<template>
    <div class="teseterTeacher">
        <div class="banner-tester">
            <p class="wrap1200">
                <img src="../../../assets/images/tester-teacher.jpg" alt="" width="1200" height="470">
            </p>
        </div>
        <div class="teset-roles wrap1200">
            <p class="tit-teset">进入使用，你是 ?</p>
            <ul>
                <li>
                    <img src="../../../assets/images/tester-role01.jpg" alt="">
                </li>
                <li>
                    <img src="../../../assets/images/tester-role02.jpg" alt="">
                </li>
                <li>
                    <img src="../../../assets/images/tester-role03.jpg" alt="">
                </li>
                <li>
                    <img src="../../../assets/images/tester-role04.jpg" alt="">
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scope>
    @import '@/assets/css/pages/testerTeacher';
</style>

<script>
    import {mapGetters} from "vuex";
    export default {
        data () {
            return {
            }
        }
    }
</script>

